.Footer-container{
    position: relative;

}
.Footer-container>hr{
    border:1px solid var(--ligthgray)
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;

}
.social-links>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 10rem;
}
.blur-f-1{
    bottom: 0rem;
    width: 26rem;right: 15%;
    filter:blur(222px);
    height: 12rem;
    background :red


}
.blur-f-2{
    bottom: 0rem;
    width: 26rem;right: 15%;
    filter:blur(222px);
    height: 12rem;
    background:rgb(255
    , 115, 0);


}